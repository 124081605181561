import classNames from "classnames"
import { useState } from "react"
import ProgressiveImage from "react-progressive-graceful-image"

export default (props) => {

	const [dimensions, setDimensions] = useState({ height: "", width: "" })

	const onImgLoad = ({ target: img }) => {
		setDimensions({
			height: img.offsetHeight,
			width: img.offsetWidth
		})
	}

	return (
		<ProgressiveImage
			src={props.src}
			srcSetData={{
				srcSet: `${props.src}`,
				sizes: `${dimensions.width}px, ${dimensions.height}px`
			}}
		>
			{(src, loading) => <img
				{...props}
				onLoad={onImgLoad}
				src={loading ? "" : src} alt={props.alt}
				className={classNames(
					props.className,
					{ "skeleton": loading }
				)}
			/>}
		</ProgressiveImage>
	)
}
